import { ThemeProvider } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import connectToStores from 'js/lib/connectToStores';
import { useLocation } from 'js/lib/useRouter';

import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import CourseraPlusHubPageMetatags from 'bundles/coursera-plus-landing-page/components/CourseraPlusHubPageMetatags';
import { useLandingPageRedesignViaPathname } from 'bundles/coursera-plus-landing-page/hooks/useLandingPageRedesign';
import { BUNDLE_MAP } from 'bundles/coursera-plus/utils/bundleUtils';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import type { InputProps as PageHeaderProps } from 'bundles/page-header/components/PageHeader';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { searchTheme } from 'bundles/search-common/searchTheme';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import 'css!./styl/index';

const styles = StyleSheet.create({
  container: {
    [`@media (max-width: ${breakPoint.sm - 1}px)`]: {
      paddingBottom: '52px', // extra padding for sticky banner
    },
  },
});

const simplifiedHeaderProps: PageHeaderProps = {
  subNavigationLinks: null,
  disableHeaderLogoUserInteraction: false,
  enableCourseraHeaderLogoOnly: true,
  hideEnterprise: true,
  hideMetaNav: true,
  hideMobileNavLinks: true,
  hideNotificationCenter: true,
  hideSearch: true,
  showShoppingCart: false,
  hideLogIn: false,
};

type PropsFromStores = {
  csrfToken: string;
  requestCountryCode: string;
};

const CourseraPlusHubApp = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { pathname } = useLocation();
  const isMarketing = pathname.includes('subscribe') || pathname.includes('special');
  const shouldDisableIndexing = isMarketing;
  const { displayRedesign, variant: redesignVariant } = useLandingPageRedesignViaPathname(pathname);
  const shouldDisablePageHeaderControls = isMarketing;
  const isCollectionLandingPage = Object.keys(BUNDLE_MAP).some((bundleId) => pathname.includes(bundleId));

  const defaultHeaderProps: PageHeaderProps = {
    isPageWithSecondaryCta: true,
    hideSearch: shouldDisablePageHeaderControls,
    hideMembershipSwitcher: shouldDisablePageHeaderControls,
    hideEnterprise: shouldDisablePageHeaderControls,
    hideRightNav: shouldDisablePageHeaderControls,
    disableHeaderLogoUserInteraction: shouldDisablePageHeaderControls,
    showShoppingCart: !shouldDisablePageHeaderControls,
    isScrollable: false,
    hideMetaNav: isCollectionLandingPage || isMarketing,
  };

  const headerProps = displayRedesign && redesignVariant === 'b' ? simplifiedHeaderProps : defaultHeaderProps;

  return (
    <ThemeProvider theme={searchTheme}>
      <div className={classNames('rc-CourseraPlusHubApp', css(styles.container).className)}>
        <CourseraPlusHubPageMetatags disableCrawlerIndexing={shouldDisableIndexing} />
        <PageHeader {...headerProps} />
        <div role="main">{children}</div>
        <PageFooter />
      </div>
    </ThemeProvider>
  );
};

export default compose<PropsFromStores, {}>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'premium_hub',
      page: 'coursera_plus_landing_page',
    },
  })),
  connectToStores<PropsFromStores, {}>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  }))
)(CourseraPlusHubApp);
